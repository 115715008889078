<template>
  <router-view />
</template>

<script>
import { mapWritableState, mapActions } from 'pinia';
import { useProcessesStore, useTemplatesStore } from '@/stores/index.js';

export default {
  name: 'Process',
  props: {
    processId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapWritableState(useProcessesStore, ['process', 'forms']),
    ...mapWritableState(useTemplatesStore, ['htmlSource', 'testTemplate']),
  },
  async created() {
    const { data, error } = await this.readProcess({ id: this.processId });

    if (error) {
      return this.$router.push({ name: 'DocumentsProcessesList' });
    }

    this.readTestTemplate({ processId: this.processId });
    this.readForms({ processId: this.processId });

    return data;
  },
  destroyed() {
    this.testTemplate = null;
    this.htmlSource = null;
    this.process = null;
    this.forms = null;
  },
  methods: {
    ...mapActions(useTemplatesStore, ['readTestTemplate']),
    ...mapActions(useProcessesStore, ['readProcess', 'readForms']),
  },
};
</script>
